'use strict';
(function($) {

  //  WebFontConfig = {
  //   google: { families: [ 'Open+Sans:400,600,700,300:latin,latin-ext', 'Raleway:400,300,500,600,700:latin,latin-ext' ] }
  // };
  //   var wf = document.createElement('script');
  //   wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
  //   wf.type = 'text/javascript';
  //   wf.async = 'true';
  //   var s = document.getElementsByTagName('script')[0];
  //   s.parentNode.insertBefore(wf, s);

  /**
   * [onTopVisible - if top limit of the element is visible]
   * @target {HTMLelement} [ hero header section for each page ]
   * @return {Object} [apply visibility: visible; with fade down animation]
   */



  $(".ui.sidebar")
      .sidebar({
        transition: 'overlay',
        mobileTransition: 'push'
      })
      .sidebar('attach events', '.ui.launch');

    $('.menu-wraper').visibility({
      type: 'fixed',
      offset: 0
    });

    $('.footer-wrap').visibility({
      once: true,
      // update size when new content loads
      observeChanges: true,
      // load content on bottom edge visible
      onTopVisible: function() {
        $(this).transition({
        animation: 'fade up in',
        duration: 1800
      });
      }
    });


  $('.hero-divisions, .hero-companies, .hero-contact, .hero-about-us, .hero-material').visibility({
    once: true,
    observeChanges: true,
    onTopVisible: function() {
      $(this).transition({
        animation: 'fade down in',
        duration: 2000
      });
    }
  });



  // initiate translation plugin
  i18next
    .use(window.i18nextXHRBackend)
    // .use(Cache)
    .init({
      lng: 'en',
      fallbackLng: 'en',

      backend: {
        // path where resources get loaded from
        loadPath: 'locales/{{lng}}/{{ns}}.json',

        // path to post missing resources
        // addPath: 'locales/add/{{lng}}/{{ns}}',

        // your backend server supports multiloading
        // /locales/resources.json?lng=de+en&ns=ns1+ns2
        allowMultiLoading: false,

        // allow cross domain requests
        crossDomain: false,

        // define a custom xhr function
        // can be used to support XDomainRequest in IE 8 and 9
        // ajax: function (url, options, callback, data) {}
      }
      // cache: {
      //     // turn on or off
      //     enabled: true,

      //     // prefix for stored languages
      //     prefix: 'i18next_res_',

      //     // expiration
      //     expirationTime: 7*24*60*60*1000
      // }
    }, function (err, t) {

      validateForm(i18next);
    });


    // add localStorage data if Romanian language is selected
    $('.rom').on('click', function() {
      localStorage.setItem('ro-Ro', '1');
    });

    // remove localStorage data if English language is selected and show proper active language
    $('.eng').on('click', function() {
      localStorage.removeItem('ro-Ro');
      $('.eng').addClass('active');
      $('.rom').removeClass('active');
    });



    $langDropdown = $('.ui.language.dropdown'); // semantic ui dropdown component

    $langDropdown.dropdown({
      on: 'click',
      onChange: function(value, text, $choice) {
        i18next
          .changeLanguage($choice.data('lang'), function(err, t) {
            i18nextJquery.init(i18next, $);
            $('#wrap-around-homepage').localize();
            $('.footer').localize();
            $('.copyright').localize();
            $('.menu-wraper').localize();
            $('.sidebar.menu').localize();
            $('.infocard').localize();
            $('.infolink').localize();
            $('#usa').localize();
            $('#ro').localize();
            $('.segmentation').localize();
            $('.info').localize();
            $('.aboutUs').localize();
            $('.divisions').localize();
            $('.divisions-overview').localize();
            $('.pointing.prompt.label').localize();
            $('#policies').localize();

            // $langDropdown.dropdown('saveRemoteData');
            $contactForm.form('revalidate');
            $contactForm.form('reset');
            $contactForm.form('destroy');
            validateForm(i18next);
            // location.reload();

          });
      }
    });


  // form validation - semantic ui
  var $contactForm = $('.ui.form');

  /**
   * Validate form - with i18next translation plugin
   */
  var validateForm = function validateForm(i18next) {

    $contactForm.form({
      fields: {
        name: {
          identifier  : 'name',
          rules: [
            {
              type   : 'empty',
              prompt : i18next.t('contact.form.validation.name')
            }
          ]
        },

        email: {
          identifier  : 'email',
          rules: [
            {
              type   : 'empty',
              prompt : i18next.t('contact.form.validation.email')
            }
          ]
        },

        subject: {
          identifier : 'subject',
          rules: [
            {
              type   : 'empty',
              prompt : i18next.t('contact.form.validation.subject')
            }
          ]
        },

        message: {
          identifier : 'message',
          rules: [
            {
              type   : 'empty',
              prompt : i18next.t('contact.form.validation.message')
            }
          ]
        }

      },
      inline : true,
      on     : 'blur'
    });

  };

  // show proper active language if Romanian language is selected
    if ( localStorage.getItem('ro-Ro') ) {
      $('.rom').addClass('active');
      $('.eng').removeClass('active');

      console.log('LANGUAGE', i18next);
      // change language to Romanian
      i18next.changeLanguage('ro', function(err, t) {
        i18nextJquery.init(i18next, $);
        $('#wrap-around-homepage').localize();
        $('.footer').localize();
        $('.copyright').localize();
        $('.menu-wraper').localize();
        $('.sidebar.menu').localize();
        $('.infocard').localize();
        $('.infolink').localize();
        $('#usa').localize();
        $('#ro').localize();
        $('.segmentation').localize();
        $('.info').localize();
        $('.aboutUs').localize();
        $('.divisions').localize();
        $('.divisions-overview').localize();
        $('.pointing.prompt.label').localize();
        $('#policies').localize();
        if ($('#policies')) {
          console.log('POLICIES');
        }
        console.log('WHAAAAAT');
        // validate form if Romanian cookie is set
        validateForm(i18next);
      });

    }



  // send form on email throught ajax
  $contactForm.on('submit', function (evt) {
      // Prevent submmiting the form normally
      evt.preventDefault();

      if ( $(evt.currentTarget).form('is valid') ) {

          var formData = $('.form').serializeArray();

          console.log(formData);
          $.ajax({
              type: 'POST',
              url: 'assets/js/contact_sendmail.php',
              dataType: 'json',
              data: formData,
              // display loader before sending the form
              beforeSend: function() {
                  $('.ui.load.modal').modal('show');
              },
              // if the form has sent successfully
              success: function(response) {

                  console.log(response);

                  if (response.success) {
                      $('.ui.error.modal').remove(); // remove loader
                      $('.ui.success.modal').modal('show'); // show success message
                      $('.form').find('input[type=text]').val(''); // empty input fields
                      $('.form').find('textarea[type=text]').val(''); // empty input fields
                      grecaptcha.reset(); // reset recaptcha
                  }
                  // if sending the form failed
                  else {

                      $('.ui.load.modal')
                          .modal('setting', 'transition', 'fade left') // show loader
                          .modal('hide'); // hide the loader modal
                      $('.ui.error.modal').modal('show'); // show error message
                  }
              }
          });
      }
  });

  // resize top fixed menu on scroll
	$(window).scroll(function() {
		if ($(this).scrollTop() > 0) {
		    $('.sidebar-menu, .menu-wraper').addClass('menu-after-scroll');
		} else {
		    $('.sidebar-menu, .menu-wraper').removeClass('menu-after-scroll');
		}
	});

})(jQuery);


var isCompaniesPage = function () {
  return window.location.pathname === '/companies.html';
}

jsPlumConfig = {
  endpoint: 'Blank',
  directStyle: { strokeWidth: 3, stroke: 'rgb(68, 85, 102)' },
  indirectStyle: { strokeWidth: 1.2, stroke: 'rgb(68, 85, 102)', 'dashstyle': "1 4" }
};

jsPlumb.ready(function () {
  jsPlumb.setContainer(document.getElementById('#lineContainer'));
  if (window.innerWidth > 768 && isCompaniesPage()) {
    drawConnections(jsPlumConfig);
  }
});

if (isCompaniesPage()) {
  window.addEventListener('resize', handleConnections);
}

function handleConnections() {
  if (window.innerWidth < 768) {
    jsPlumb.deleteEveryEndpoint();
  } else {
    if (jsPlumb.getConnections().length) {
      jsPlumb.repaintEverything();
    } else {
      drawConnections(jsPlumConfig);
    }
  }
}

function drawConnections(config) {

  jsPlumb.connect({
    source: 'ietCard',
    target: 'geobetaCard',
    anchors: ['Top', 'Left'],
    connector: ['Flowchart'],
    paintStyle: config.indirectStyle,
    endpoint: config.endpoint,
  });

  jsPlumb.connect({
    source: 'geobetaCard',
    target: 'mfCard',
    anchors: ['Right', 'Top'],
    connector: ['Flowchart'],
    paintStyle: config.indirectStyle,
    endpoint: config.endpoint,
  });

  jsPlumb.connect({
    source: 'mfCard',
    target: 'orviCard',
    anchors: ['Top', 'Top'],
    connector: ['Flowchart'],
    paintStyle: config.indirectStyle,
    endpoint: config.endpoint,
  });

  jsPlumb.connect({
    source: 'geobetaCard',
    target: 'imobCard',
    anchors: ['Bottom', 'Top'],
    connector: ['Flowchart'],
    paintStyle: {
      strokeWidth: 2,
      stroke: 'rgb(204, 202, 202)'
    },
    endpoint: config.endpoint,
  });

  jsPlumb.connect({
    source: 'ietCard',
    target: 'gmabCard',
    anchors: ['Left', 'Left'],
    connector: ['Flowchart'],
    paintStyle: config.indirectStyle,
    endpoint: config.endpoint,
  });

  jsPlumb.connect({
    source: 'gmabCard',
    target: 'ameconCard',
    anchors: ['Top', 'Top'],
    connector: ['Flowchart'],
    paintStyle: {
      strokeWidth: 2,
      stroke: 'rgb(204, 202, 202)'
    },
    endpoint: config.endpoint,
  });

  jsPlumb.connect({
    source: 'imobCard',
    target: 'ameconCard',
    anchors: ['Bottom', 'Top'],
    connector: ['Flowchart'],
    paintStyle: {
      strokeWidth: 2,
      stroke: 'rgb(204, 202, 202)'
    },
    endpoint: config.endpoint,
  });

  jsPlumb.connect({
    source: 'ameconCard',
    target: 'flamarexCard',
    anchors: ['Top', 'Top'],
    connector: ['Flowchart'],
    paintStyle: {
      strokeWidth: 2,
      stroke: 'rgb(204, 202, 202)'
    },
    endpoint: config.endpoint,
  });

  jsPlumb.connect({
    source: 'flamarexCard',
    target: 'lemnestCard',
    anchors: ['Top', 'Top'],
    connector: ['Flowchart'],
    paintStyle: {
      strokeWidth: 2,
      stroke: 'rgb(204, 202, 202)'
    },
    endpoint: config.endpoint,
  });

  jsPlumb.connect({
    source: 'gmabCard',
    target: 'euroamCard',
    anchors: ['Bottom', 'Top'],
    connector: ['Flowchart'],
    paintStyle: {
      strokeWidth: 2.5,
      stroke: 'rgb(204, 202, 202)'
    },
    endpoint: config.endpoint,
  });

  jsPlumb.connect({
    source: 'euroamCard',
    target: 'arcCard',
    anchors: ['Top', 'Top'],
    connector: ['Flowchart'],
    paintStyle: {
      strokeWidth: 2,
      stroke: 'rgb(204, 202, 202)'
    },
    endpoint: config.endpoint,
  });

  jsPlumb.connect({
    source: 'arcCard',
    target: 'mandfitCard',
    anchors: ['Top', 'Top'],
    connector: ['Flowchart'],
    paintStyle: {
      strokeWidth: 2,
      stroke: 'rgb(204, 202, 202)'
    },
    endpoint: config.endpoint,
  });

}

//
console.log('INIT COOKIE CONSENT');
window.cookieconsent.initialise({
  palette: {
    popup: {
      // "background": "#000"
      "background": "rgb(34,58,102, 0.9)"
      // "background": "rgb(27, 77, 147, 0.9)"
    },
    button: {
      "background": "transparent",
      "border": "#FFF",
      "text": "#FFF"
    }
  },
  content: {
    href: '/policies.html'
  },
  cookie: {
    name: "geobeta-cookieconsent"
  },
});
