
function lazyLoadGoogleMap() {
    $.getScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyC3o04McZU5NHjOrZrNRg_MOetiM078_g0&callback=initMap')
        .done(function (script){
            // initializeMap();
            // initMap();
        })
        .fail(function(jqxhr, settings, ex) {
            alert("Could not load google map!" + jqxhr);
        });
}


// function initializeMap() {

    function initMap(param) {

        var myLatLng = {lat: 42.6068566, lng: -83.1083916};

        var LatLng = {lat: 42.6088566, lng: -83.1083916};

        var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 15,
            center: LatLng,
            scrollwheel: true,
            backgroundColor: '#f6f6f6',
            styles: [
                {
                    "featureType": "all",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        },
                        {
                            "hue": "#ff0000"
                        }
                    ]
                },
                {
                    "featureType": "administrative.province",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 65
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 51
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 30
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 40
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "lightness": -25
                        },
                        {
                            "saturation": -97
                        },
                        {
                            "color": "#2185D0"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "lightness": -25
                        },
                        {
                            "saturation": -100
                        }
                    ]
                }
            ]
        });

        google.maps.event.addListenerOnce(map, 'idle', function() {
            infowindow.open(map, marker);
        });


        var contentString =
            '<div class="ui grid info">' +
                '<div class="row">' +
                    '<div class="sixteen wide column">' +
                        '<div class="row">' +
                            '<div class="ui header center aligned">' +
                                // '<img class="ui  image" src="../images/logo2.png">' +
                            '</div>' +
                        '</div>' +
                        '<div class="row" style="padding: 20px;">' +
                            '<h3 data-i18n="map.info" class="ui header center aligned">' +
                                'Office Contact Details:' +
                            '</h3>' +
                        '</div>' +
                        '<ul>' +
                            '<li>' +
                                '<a href="#">' +
                                    '<i class="ui large grey marker icon"></i>' +
                                    'Troy, Michigan, United States' +
                                '</a>' +
                            '</li>' +

                            // '<li>' +
                            //     '<a href="#">' +
                            //         '<i class="ui large grey square phone icon"></i>' +
                            //         '+1 248-828-1738' +
                            //     '</a>' +
                            // '</li>' +

                            // '<li>' +
                            //     '<a href="#">' +
                            //         '<i class="ui large grey fax icon"></i>' +
                            //         '+1 248-828-1738' +
                            //     '</a>' +
                            //     '</li>' +

                            '<li>' +
                                '<a href="#">' +
                                    '<i class="ui large grey mail icon"></i>' +
                                    'office@geobeta.com' +
                                '</a>' +
                            '</li>' +

                        '</ul>' +
                    '</div>' +
                '</div>' +
            '</div>'

        ;

        var infowindow = new google.maps.InfoWindow({

            content: '<div class="scrollFix">'+contentString+'</div>',
            disableAutoPan: false

        });

        var marker = new google.maps.Marker({

            position: myLatLng,
            map: map,
            title: 'Geobeta'

        });


        // infowindow.open(map, marker);

        marker.addListener('click', function() {
            window.setTimeout(function() {
                map.setCenter(myLatLng);
            }, 0);
            infowindow.open(map, marker);

        });

    };
// }



